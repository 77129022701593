import React from 'react'
import PokerLayout from '../../../components/Poker/PokerLayout'
import { Breadcrumb } from '../../../components/Breadcrumbs/Breadcrumbs'
import { POKER, POKER_PLAY_LIKE_PRO } from '../../../components/internal-links'
import { graphql, useStaticQuery } from 'gatsby'
import RenderHTML from '../../../components/Poker/PokerVarientPages/RenderHTML'
import {
  HOMEPAGE_BREADCRUMB_TITLE,
  POKER_BREADCRUMB_TITLE,
  POKER_VARIANTS_BREADCRUMB_TITLE,
} from '../../../utils/constnst.utils'

const PokerPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allGhostPost(filter: { slug: { eq: "how-to-play-poker-like-a-pro" } }) {
        nodes {
          html
          title
          meta_title
          meta_description
        }
      }
    }
  `)

  const title = data.allGhostPost.nodes[0]?.title
  const breadcrumbs: Breadcrumb[] = [
    {
      title: HOMEPAGE_BREADCRUMB_TITLE,
      url: '/',
    },
    {
      title: POKER_VARIANTS_BREADCRUMB_TITLE,
      url: POKER,
    },
    {
      title: title,
      url: POKER_PLAY_LIKE_PRO,
    },
  ]

  const metaTitle = data.allGhostPost.nodes[0]?.meta_title
  const metaDescription = data.allGhostPost.nodes[0]?.meta_description

  return (
    <>
      <PokerLayout
        showFeatures={true}
        showOtherContent={false}
        showVideos={false}
        pageName="custom"
        breadcrumbs={breadcrumbs}
        showFullScreenBanner={true}
        pageSlug={{
          POKER_LINK: POKER_PLAY_LIKE_PRO,
        }}
        seoDetails={{
          title: metaTitle,
          description: metaDescription,
        }}
        englishContentHTML={
          <RenderHTML html={data.allGhostPost.nodes[0]?.html} title={title} />
        }
      />
    </>
  )
}

export default PokerPage
